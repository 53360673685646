<template>
  <div class="nj-grid">
    <nj-question-dialog
      ref="removeQuestionDialog"
      title="Excluir Agendamento de Tarefas"
      text="Deseja realmente excluir o agendamento de tarefa?"
    />
    <div class="header">
      <div class="title">
        <v-icon>mdi-shield-account-outline</v-icon>
        {{ $route.meta.title || $route.name }}
      </div>
      <v-btn
        icon
        title="Retornar"
        class="align-self-baseline"
        :to="{ name: 'TaskSchedulers' }"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </div>
    <div class="ng-grid-body">
      <Form :disabled="true">
        <div class="form-actions">
          <v-btn class="cancel" elevation="0" @click.prevent="remove()">
            <v-icon>mdi-delete</v-icon>
            Excluir
          </v-btn>
          <v-btn
            elevation="0"
            color="primary"
            :to="{
              name: 'TaskSchedulerEdit',
              params: { id: this.$route.params.id },
            }"
          >
            <v-icon>mdi-clipboard-edit-outline</v-icon>
            Editar
          </v-btn>
        </div>
      </Form>
    </div>
  </div>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
import Form from './form'

export default {
  name: 'TaskSchedulerShow',
  components: { Form },

  methods: {
    remove() {
      if (!this.$hasPermission('naja_app_roles_destroy')) {
        alert('Acesso negado')
        return
      }

      this.$refs.removeQuestionDialog.open({
        onConfirm: () => {
          this.$refs.removeQuestionDialog.toggleLoading()
          Api.taskSchedulers
            .destroy(this.$route.params.id)
            .then(async () => {
              this.$router.push({
                name: 'TaskSchedulers',
                query: { refresh: true },
              })
            })
            .catch(error => {
              if (error.response) {
                this.$toast.show(
                  'Erro ao remover Agendamento de Tarefas',
                  'error'
                )
              } else {
                throw error
              }
            })
        },
      })
    },
  },
}
</script>
